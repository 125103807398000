import React from 'react';
import { graphql } from 'gatsby';
import Link from '@sentry/static-shared/components/link';
import styled from '@emotion/styled';
import { colorText } from '@sentry/static-shared/utils/css/colors';
import Layer from '@sentry/static-shared/components/Layer';
import Alert from '@sentry/static-shared/components/alert';
import Prose from '@sentry/static-shared/components/Prose';
import CustomMDXRenderer from '@sentry/static-shared/components/CustomMDXRenderer';

import Layout from './layout';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';
import SearchLayer from '../search-layer';
import MoreHelpLayer from '../more-help-section';
import {
  FlexGrid,
  FlexGridCell,
} from '@sentry/static-shared/components/FlexGrid';

import Line from '@sentry/static-shared/components/Line';

const ArticleLayout = ({ data }) => {
  const { article } = data;
  const { title, body, excerpt, category, section } = article;
  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('help', locationSlug.pathname);

  return (
    <Layout
      seo={{
        title,
        description: excerpt,
        canonical: canonicalLink
      }}
    >
      <SearchLayer />

      <Layer>
        {/* TODO: current plan is to have the section link take you to category page anchor link */}
        <StyledDivBreadcrumbs>
          <Link to="/">Help Center</Link> /{' '}
          <Link to={category.url}>{category.title}</Link> /{' '}
          <Link to={category.url + '#' + section.slug}>{section.title}</Link> /{' '}
          {title}
        </StyledDivBreadcrumbs>

        <FlexGrid justifyContent="center">
          <FlexGridCell md={10}>
            <h1>{title}</h1>
            <Prose>
              <CustomMDXRenderer body={body} shortcodes={{ Alert }} />
            </Prose>
          </FlexGridCell>
        </FlexGrid>
        <Line variant="line" rotation="left" margins={true} />
        <MoreHelpLayer />
      </Layer>
    </Layout>
  );
};

export default ArticleLayout;

const StyledDivBreadcrumbs = styled.div`
  margin-bottom: 4rem;

  a {
    transition: color 0.2s;

    &:hover {
      color: ${colorText};
      text-decoration: none;
    }
  }
`;

export const pageQuery = graphql`
  query ArticleQuery($id: String) {
    article(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 160)
      category {
        url
        title
      }
      section {
        url
        title
        slug
      }
      title
    }
  }
`;
